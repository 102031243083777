import styled from 'styled-components'
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { v4 as uuidv4 } from 'uuid'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Virtual } from 'swiper'
import { useTranslation } from 'react-i18next'
import { usePageContext }  from '@hooks/usePageContext'
import adOg from "@images/logos/ad-original-logo.png" 
import 'swiper/css/bundle'


const StoryTitleSmall = styled.h3`
  margin: 0px 0px;
  font-size:18px;
  font-family: 'Romana Becker Demi';
  display:block;
`;

const StoryMetaSmall = styled.h4`
  font-size:12px;
  margin: 0px 0px;
  text-transform:uppercase;
  font-family: 'Poppins';
  color: #A68C8A;
  font-weight:500;
`;

const SliderTitle = styled.h2`
  font-size:12px;
  font-family: 'Poppins';
  margin: 0px 0px;
  padding-bottom:20px;
`
const SliderSubTitle = styled.h3`
  font-size:25px;
  margin: 0px 0px;
  
`

const StorySliderLink = styled(Link)`
  text-decoration: none;
  color:#000000;
`

const StorySliderItemMeta = styled.div`
  padding:5% 5% 0% 5%;
  text-align: center;
  margin-bottom: 0px;
`


const StoryRow = styled.div`
  position:relative;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom:30px;
  @media(min-width: 768px){
    padding:0 10%;
  }
  padding:0 5%;
`

const OriginalContentImg = styled.img`
  z-index: 10;
  height: 25px;
  width: 25px;
  top: 15px;
  position: absolute;
  left: 15px;
  transform: translate3d(0,0,0) translateZ(1000px); // fixes safari transform bug
`

const CoverImage = styled(GatsbyImage)`
  z-index:5;
  max-width:300px;
  display: block;
`

const SliderImageWrapper =  styled.div`
  display: block;
  border-radius:5px;
  overflow: hidden;
  transform: translate3d(0,0,0) translateZ(1000px); // fixes safari transform bug
`

const settings = {
  init: false,
  slidesPerView: 1.5,
  spaceBetween: 20,
  freeMode: true,
  breakpoints: {
    600: {
      slidesPerView: 3,
      spaceBetween: 50,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 50,
    },
    1900: {
      slidesPerView: 4,
      spaceBetween: 80,
    }
  }
}

 const StorySlider = (props) => {
  const {lang} = usePageContext()
  const {episodes, title, subtitle} = props
  const { t } = useTranslation()
  SwiperCore.use([Virtual]);

  return (
    <StoryRow >
      <SliderSubTitle user={props.user}>{title}</SliderSubTitle>
      <SliderTitle user={props.user}>{subtitle}</SliderTitle>
      <Swiper  {...settings}  style={{overflow: 'visible'}}> 
        {episodes.map((episode, index) => (
          <SwiperSlide key={uuidv4()} virtualIndex={index}>  
            <StorySliderLink to={`${t('urls.sleepEpisode')}${episode.node[lang].slug}/`}>
              <SliderImageWrapper>
                <OriginalContentImg src ={adOg}></OriginalContentImg>
                <CoverImage image={episode?.node?.localImage?.coverImage?.gatsbyImageData}  alt={`${episode.node[lang].title} Erotic Audio Story Audiodesires`}/> 
              </SliderImageWrapper>
              <StorySliderItemMeta>
                <StoryTitleSmall>{episode.node[lang].title}</StoryTitleSmall>
                <StoryMetaSmall>{episode.node[lang]?.voices[0]?.name}{`${episode.node[lang]?.voices[1] ? ` + ${episode.node[lang]?.voices[1]?.name}`:""}`}</StoryMetaSmall>
              </StorySliderItemMeta>
            </StorySliderLink>
          </SwiperSlide> 
        ))}  
      </Swiper> 
    </StoryRow>
  );
}
export default StorySlider