import styled from 'styled-components'
import React, { useContext } from "react"
import {FirebaseContext} from '../Firebase'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Virtual } from 'swiper'
import { useTranslation } from 'react-i18next'
import 'swiper/css/bundle'
import { v4 as uuidv4 } from 'uuid'
import StorySliderItem from '@common/slider/StorySliderItem'

const StoryRow = styled.div`
  position:relative;
  margin-top:${props => props.mt};
`

const RowTitle =styled.h2`
  font-size: 25px;
  margin: 0px 0px;
  ${props => props.pb && "padding-bottom: 20px;"}
`

const RowSubTitle =styled.h3`
  font-size: 12px;
  font-family: 'Poppins';
  margin: 0px 0px;
  ${props => props.pb && "padding-bottom: 20px;"}
`

const StorySlider = (props) => {
  const {stories, title, subtitle, locks, settings,mt,lang , reverseTitle} = props
  const {user} = useContext(FirebaseContext);
  const { t } = useTranslation()
  SwiperCore.use([Virtual]);
    
  return (
    <StoryRow mt={mt}>
      {reverseTitle 
        ? <>
            <RowTitle user={props.user} >{title}</RowTitle>
            <RowSubTitle pb user={props.user} >{subtitle}</RowSubTitle>
          </>
          :
          <>
            <RowSubTitle user={props.user} >{title}</RowSubTitle>
            <RowTitle pb user={props.user} >{subtitle}</RowTitle>
          </>
      }
      <Swiper  {...settings} style={{overflow: 'visible'}}> 
        {stories.slice(0,10).map((story, index) => (
          <SwiperSlide key={uuidv4()} virtualIndex={index}>  
            <StorySliderItem
              spiciness={story.node.spiciness}
              voices={story.node[lang].voices}
              title={story.node[lang].title}
              coverImage={story.node.localImage?.coverImage}
              premiumContent={story.node[lang].premiumContent}
              audiodesiresOriginal={true}
              link={`${t('urls.sleepEpisode')}${story.node[lang].slug}/`}
              duration={story.node[lang].duration}
              user={user}
              locks={locks}
            />
          </SwiperSlide> 
        ))}  
      </Swiper> 
    </StoryRow>
  );
}

export default StorySlider