import React, {useContext} from 'react'
import styled from 'styled-components'; 
import { graphql } from 'gatsby'
import StoryArraySlider from "@components/sleep/StoryArraySlider";
import Header from '../components/common/menus/header';
import Seo from "@components/Seo";
import { useTranslation } from 'react-i18next';
import SleepLoggedOut from '../components/sleep/SleepLoggedOut';
import {FirebaseContext} from '@components/Firebase'

const params = {
  slidesPerView: 1.5,
  spaceBetween: 15,
  freeMode: true,
  breakpoints: {
    374: {
      slidesPerView: 1.8,
      spaceBetween: 20,
    },
    600: {
      slidesPerView: 2.3,
      spaceBetween: 20,
    }
  }
}

const Wrapper = styled.div`
  background-color:${props => props.theme.backgroundColor};
  padding:5%;
  overflow:hidden;
  min-height:100vh;
`;

const Sleep = (props) => {
  const {data:{allSleepEpisode:{edges:allEpisodes}}} = props
  const {pageContext:{lang}} = props
  const { t } = useTranslation(['translations','pages'])
  const { user } = useContext(FirebaseContext);

  const sliders = [
    {
      episodes: allEpisodes.filter(episode => episode.node.sleepGenre.en.genre === "Unwind"),
    },
    {
      episodes: allEpisodes.filter(episode => episode.node.sleepGenre.en.genre  === "Soundscape")
    },
    {
      episodes: allEpisodes.filter(episode => episode.node.sleepGenre.en.genre  === "Fleeting Moment")
    },
    {
      episodes: allEpisodes.filter(episode => episode.node.sleepGenre.en.genre  === "Sleep Meditation")
    }
  ]


  return (  
    <>
      <Header location={props.location}/>
      <Seo 
        title={t('pages:sleep.pageTitle')}
        description={t('pages:sleep.metaDescription')}
      />
        {!!user ?
          <Wrapper>
            {t('pages:sleep.sliders', {returnObjects: true}).map(({title, subtitle},index) => 
              <>
                {sliders[index].episodes.length > 0 &&
                  <StoryArraySlider
                    mt="20px"
                    settings={params} 
                    stories={sliders[index].episodes} 
                    title={title}
                    subtitle={subtitle}
                    user={true}
                    locks
                    lang={lang}
                    reverseTitle={true}
                />
              }
              </>
          )}
          </Wrapper>
        :
          <SleepLoggedOut episodes={sliders}/>
        }
    </>
  )
}

export default Sleep


export const query = graphql`
  query AllSleepEpisode($lang: String, $isEn: Boolean!, $isEs: Boolean!, $isDe: Boolean!){
    allSleepEpisode(filter: {languages: {in: [$lang]}}) {
      edges {
        node {
          id
          imageUrl
          en @include(if: $isEn)  {
            accent {
              accent
            }
            voices{
              id
              name
              gender
            }
            duration
            featured
            premiumContent
            published
            metaDescription
            seoTitle
            seriesEpisodes
            slug
            summary
            title
          }
          es @include(if: $isEs)  {
            accent {
              accent
            }
            voices{
              id
              name
              gender
            }
            duration
            featured
            premiumContent
            published
            metaDescription
            seoTitle
            seriesEpisodes
            slug
            summary
            title
          }
          de @include(if: $isDe)  {
            accent {
              accent
            }
            voices{
              id
              name
              gender
            }
            duration
            featured
            premiumContent
            published
            metaDescription
            seoTitle
            seriesEpisodes
            slug
            summary
            title
          }
          scenario {
            en  @include(if: $isEn) {
              scenario
            }
            es  @include(if: $isEs) {
              scenario
            }
            de  @include(if: $isDe) {
              scenario
            }
          }
          localImage {
            coverImage: childImageSharp {
              gatsbyImageData(width: 600, height: 600, layout: CONSTRAINED)
            }
          }
          localImage {
            smallImage: childImageSharp {
              gatsbyImageData( placeholder: BLURRED,width: 100, height: 100, layout: CONSTRAINED)
            }
          }
          localImage {
            thumbnail: childImageSharp {
              gatsbyImageData( placeholder: BLURRED,width: 100, height: 100, layout: CONSTRAINED)
            }
          }
          sleepGenre {
            id
            en {
              genre
            }
            es  @include(if: $isEs) {
              genre
            }
            de  @include(if: $isDe) {
              genre
            }
            id
          }
          mainTag {
            id
            en  @include(if: $isEn) {
              tag
            }
            es  @include(if: $isEs) {
              tag
            }
            de  @include(if: $isDe) {
              tag
            }
          }
          tags {
            id
            en  @include(if: $isEn) {
              tag
              slug
            }
            es  @include(if: $isEs) {
              tag
              slug
            }
            de  @include(if: $isDe) {
              tag
              slug
            }
          }
        }
      }
    }

    allSleepTag(filter: {languages: {in: [$lang]}}) {
      edges {
        node {
          id
          languages
          en @include(if: $isEn) {
            tag
            slug
          }
          es @include(if: $isEs) {
            tag
            slug
          }
          de @include(if: $isDe) {
            tag
            slug
          }
        }
      }
    }
  }
`