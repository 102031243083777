import React from "react"
import HomeHeroSection from '@loggedOut/HomeHeroSection'
import HeroMenu  from '@menus/HeroMenu'
import { useTranslation } from 'react-i18next'
import StorySlider from "./StorySlider"

const SleepLoggedOut = ({episodes}) => {
  const { t } = useTranslation(['translations','pages'])

  return (
    <>
      <HeroMenu/>
      <HomeHeroSection 
        headerTitle={t('pages:sleep.loggedOutTitle')}
        headerText={t('pages:sleep.loggedOutDescription')}
        ctaText={t('pages:root.getStarted')}
      />
      {t('pages:sleep.sliders', {returnObjects: true}).map(({title, subtitle},index) => 
        <StorySlider
          episodes={episodes[index].episodes} 
          title= {title}
          subtitle={subtitle}
        />
      )}
    </>
  )
}

export default SleepLoggedOut